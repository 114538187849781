import { Language } from 'interfaces/Language'
import BiologySubjectIcon from 'assets/homepage/subjects/homepage_subject_biology.svg'
import ChemistrySubjectIcon from 'assets/homepage/subjects/homepage_subject_chemistry.svg'
import EnglishSubjectIcon from 'assets/homepage/subjects/homepage_subject_english.svg'
import GermanSubjectIcon from 'assets/homepage/subjects/homepage_subject_german.svg'
import MathSubjectIcon from 'assets/homepage/subjects/homepage_subject_math.svg'
import HistorySubjectIcon from 'assets/homepage/subjects/homepage_subject_history.svg'
import FrenchSubjectIcon from 'assets/homepage/subjects/homepage_subject_french.svg'
import PolishSubjectIcon from 'assets/homepage/subjects/homepage_subject_polish.svg'
import WorldSubjectIcon from 'assets/homepage/subjects/homepage_subject_world.svg'
import AhmadScreenshot from 'assets/homepage/ahmad.webp'
import TimScreenshot from 'assets/homepage/tim_1.webp'
import PaulaScreenshot from 'assets/homepage/paula.webp'
import Dawid1Screenshot from 'assets/homepage/dawid-1.webp'
import Dawid2Screenshot from 'assets/homepage/dawid-2.webp'
import OskarScreenshot from 'assets/homepage/oskar.webp'
import KubaScreenshot from 'assets/homepage/kuba.webp'
import MarysiaScreenshot from 'assets/homepage/marysia.webp'
import StudyWithMeScreenshot from 'assets/homepage/studywithme.webp'
import BuniworldScreenshot from 'assets/homepage/buniworld.webp'
import JosephWellsScreenshot from 'assets/homepage/joseph_wells.webp'
import JoshBrownScreenshot from 'assets/homepage/josh_brown.webp'
import MattGreenScreenshot from 'assets/homepage/matt_green.webp'
import StevieScreenshot from 'assets/homepage/stevie.webp'
import DDFonlineScreenshot from 'assets/homepage/ddfonline.webp'
import GiusyScreenshot from 'assets/homepage/giusy.webp'
import HeyProfScreenshot from 'assets/homepage/heyprof.webp'
import InfoTricksScreenshot from 'assets/homepage/infotricks.webp'
import SonoloiScreenshot from 'assets/homepage/sonoloi.webp'
import NikiScreenshot from 'assets/homepage/niki.webp'
import LandryScreenshot from 'assets/homepage/landry.webp'
import EliseScreenshot from 'assets/homepage/elise.webp'
import SpencerScreenshot from 'assets/homepage/spencer.webp'

import theme from '../../stories/utils/theme'
import { TFunction } from 'i18next'
import { ReactNode } from 'react'

export interface HomepageSubject {
    title: string
    icon: ReactNode
    knowUuids: string[]
    color: string
}

export const returnSubjects = (contentLanguage: Language, t: TFunction<'homepage'>): HomepageSubject[] => {
    switch (contentLanguage) {
        case Language.French:
            return [
                {
                    title: t('homepage/subjectMath'),
                    icon: <MathSubjectIcon />,
                    knowUuids: [
                        'fac80cbc-ae25-4329-90c0-41c25dc3ce6d',
                        '18f732fd-e9c2-488f-aa70-30af3f673ab9',
                        '17183a79-5911-40d9-9f50-2f3d53cf6677',
                    ],
                    color: theme.colors.subjectGreen,
                },
                {
                    title: t('homepage/subjectFrench'),
                    icon: <FrenchSubjectIcon />,
                    knowUuids: [
                        'e078501d-3044-4e8e-8a8d-05bc3f240d66',
                        '147ee95c-9463-44c2-aa37-a7b6338c04db',
                        '021ca582-cb5a-4ec2-bb9e-3523957596d1',
                    ],
                    color: theme.colors.subjectBlue,
                },
                {
                    title: t('homepage/subjectEnglish'),
                    icon: <EnglishSubjectIcon />,
                    knowUuids: [
                        'e7cde76d-62f5-4030-8e25-e051132a3a8d',
                        '7de61ebb-61cc-46ef-b3e8-9aa8f58facc7',
                        '34efe4ac-a03f-4ff1-833c-fa195e9a5cd6',
                    ],
                    color: theme.colors.subjectBlue,
                },
                {
                    title: t('homepage/subjectHistory'),
                    icon: <HistorySubjectIcon />,
                    knowUuids: [
                        'd45a2511-a907-430f-91f2-071c74af308f',
                        'ff3751da-8f89-432c-acb1-4f006f9da17e',
                        '77b509f2-1d60-454f-b913-48a1959b143c',
                    ],
                    color: theme.colors.subjectYellow,
                },
                {
                    title: t('homepage/subjectChemistry'),
                    icon: <ChemistrySubjectIcon />,
                    knowUuids: [
                        'c6f6cb1c-e39e-4d0b-9427-9be16c3836d3',
                        '6a294d7b-4686-4da9-966b-39d5aa4b1df4',
                        '22e97a4d-ae1e-446e-80c2-d379af80457a',
                    ],
                    color: theme.colors.subjectGreen,
                },
                {
                    title: t('homepage/subjectBiology'),
                    icon: <BiologySubjectIcon />,
                    knowUuids: [
                        '63842ec7-5ee6-4dac-b718-970e08605a2f',
                        '0b0fea91-a561-49e4-ab60-40fb2e0ed49f',
                        'af4e01aa-bb41-4773-9d51-eed0a65ee718',
                    ],
                    color: theme.colors.subjectGreen,
                },
            ]
        case Language.Polish:
            return [
                {
                    title: t('homepage/subjectMath'),
                    icon: <MathSubjectIcon />,
                    knowUuids: [
                        '120e54be-b0d1-4c58-943e-c812850c0886',
                        '2545c1a2-37c2-4087-a439-8669d8030ac9',
                        '6a156ea4-0bc0-42db-8acc-4b287e9301e6',
                    ],
                    color: theme.colors.subjectGreen,
                },
                {
                    title: t('homepage/subjectPolish'),
                    icon: <PolishSubjectIcon />,
                    knowUuids: [
                        'f5a4fd95-66d6-4c5c-9448-8c75a6662465',
                        '21ad2b7a-5ebe-46e2-99fb-2a1c00775c82',
                        '618d6e0a-7f24-40d5-8ae5-d2ddc8257061',
                    ],
                    color: theme.colors.subjectBlue,
                },
                {
                    title: t('homepage/subjectBiology'),
                    icon: <BiologySubjectIcon />,
                    knowUuids: [
                        'e136e1d9-de1a-408c-8353-aed614a17df8',
                        '331c9bf7-8996-48de-a30d-6eba70e6ad7e',
                        'a19f80e1-6fb3-4728-af24-51ae54c9aa31',
                    ],
                    color: theme.colors.subjectGreen,
                },
                {
                    title: t('homepage/subjectEnglish'),
                    icon: <EnglishSubjectIcon />,
                    knowUuids: [
                        '6b748593-5c2f-4e30-ad4e-0dc4a09c45b7',
                        '674954e0-2b94-4ac2-bec9-a2f17467648a',
                        '9aebf34a-7ddf-4335-bf7d-0f42a5f50db7',
                    ],
                    color: theme.colors.subjectBlue,
                },
                {
                    title: t('homepage/subjectHistory'),
                    icon: <HistorySubjectIcon />,
                    knowUuids: [
                        '9aba8339-2bd3-4b3d-9f43-1513f45dae22',
                        'fd8d07c6-e2cb-4f34-9755-dcb5449faad0',
                        'b8aeab6a-00b7-49a4-8828-74214982b8f8',
                    ],
                    color: theme.colors.subjectYellow,
                },
                {
                    title: t('homepage/subjectChemistry'),
                    icon: <ChemistrySubjectIcon />,
                    knowUuids: [
                        'c6f6cb1c-e39e-4d0b-9427-9be16c3836d3',
                        '91941e5d-758a-4766-b1b9-7817b4e7dba6',
                        'e230bdf4-4b34-4e89-b68f-1f0a71d265ea',
                    ],
                    color: theme.colors.subjectGreen,
                },
            ]
        case Language.German:
            return [
                {
                    title: t('homepage/subjectMath'),
                    icon: <MathSubjectIcon />,
                    knowUuids: [
                        '7634d96e-3d79-4cf8-a67c-b76cc65fe247',
                        '7ded2114-d5bf-47ea-b80e-0b65451f5296',
                        'da9d5011-6207-41ee-b363-bd124182c60f',
                    ],
                    color: theme.colors.subjectGreen,
                },
                {
                    title: t('homepage/subjectGerman'),
                    icon: <GermanSubjectIcon />,
                    knowUuids: [
                        '1145e9bb-7980-4472-93df-de28486b13d1',
                        'f09c22d2-2045-4c89-a794-1bf7ac48059f',
                        '6f252c72-bcf8-44ef-b793-4beb2f91d7be',
                    ],
                    color: theme.colors.subjectBlue,
                },
                {
                    title: t('homepage/subjectEnglish'),
                    icon: <EnglishSubjectIcon />,
                    knowUuids: [
                        'ce6025d7-b908-43d3-a392-5aab7b46f188',
                        '430e3284-9694-4fd3-82fc-000fc709b7fc',
                        '91116033-8390-4daa-82ad-048955aace27',
                    ],
                    color: theme.colors.subjectBlue,
                },
                {
                    title: t('homepage/subjectHistory'),
                    icon: <HistorySubjectIcon />,
                    knowUuids: [
                        '00bf7364-c2e8-48a5-94d1-8a116a6bfed0',
                        '198e7739-82a2-4e88-9258-23951ad48768',
                        '735ba515-f7ef-428a-9198-024ea0e9f70a',
                    ],
                    color: theme.colors.subjectYellow,
                },
                {
                    title: t('homepage/subjectChemistry'),
                    icon: <ChemistrySubjectIcon />,
                    knowUuids: [
                        'ee302ba7-21c6-428f-b024-49afd1b70198',
                        'f7187811-5f6c-4de1-9ddc-85639356133b',
                        'fb36f4f7-eba5-4cae-a907-ed9a0570c193',
                    ],
                    color: theme.colors.subjectGreen,
                },
                {
                    title: t('homepage/subjectBiology'),
                    icon: <BiologySubjectIcon />,
                    knowUuids: [
                        '209e4ef4-58b0-47cb-b03d-a735f3f48e50',
                        '11e553d3-f9ab-4c05-baad-7e41842e69f1',
                        '4a2b631d-87f0-4d41-b843-fcf7485d2514',
                    ],
                    color: theme.colors.subjectGreen,
                },
            ]
        case Language.AmericanEnglish:
            return [
                {
                    title: 'Algebra I',
                    icon: <MathSubjectIcon />,
                    knowUuids: [
                        '3f50f7ab-3931-4910-b756-9e14eeb0c2fd',
                        '411c3dc4-fd2e-46ea-b762-744289f390f9',
                        'b1090a85-3f57-41b9-a233-da1fad5205ed',
                    ],
                    color: theme.colors.subjectBlue,
                },
                {
                    title: 'Biology',
                    icon: <BiologySubjectIcon />,
                    knowUuids: [
                        '25f9cdf8-10d6-4ca0-bd54-5c1f25a7ae1a',
                        'ccc718a5-c88e-436d-b68a-0b148fbd75fc',
                        'a0df46ad-5301-4b99-a520-920e5badc6c4',
                    ],
                    color: theme.colors.subjectGreen,
                },
                {
                    title: 'US History',
                    icon: <HistorySubjectIcon />,
                    knowUuids: [
                        'b0f3cbd1-93e4-4635-a04e-d885f434de00',
                        'a95988be-01b4-4240-9b2f-503b9b0cc174',
                        '7bcf2af6-43e6-4523-8607-a64f305e6cb9',
                    ],
                    color: theme.colors.subjectYellow,
                },
                {
                    title: 'Spanish',
                    icon: <HistorySubjectIcon />,
                    knowUuids: [
                        'bc1e2e9d-c435-4d66-baf7-00f604b19e06',
                        'e1a3984f-aead-4bdd-a7e7-0054f93ab2b9',
                        '10e6eedf-1e3e-479b-864b-10f258a8bcc0',
                    ],
                    color: theme.colors.subjectBlue,
                },
                {
                    title: 'Earth Science',
                    icon: <EnglishSubjectIcon />,
                    knowUuids: [
                        '92939bb3-ca9f-4ac5-b3b8-b2da320ee0e7',
                        '83de8702-0408-4023-aafa-e48782e36631',
                        '07040dcd-7ac6-408e-a30b-f74ce14ac0f5',
                    ],
                    color: theme.colors.subjectGreen,
                },
            ]
        case Language.BritishEnglish:
            return [
                {
                    title: t('homepage/subjectMath'),
                    icon: <MathSubjectIcon />,
                    knowUuids: [
                        'e12404ba-6ae5-40ba-b477-f6afda583193',
                        '5bed7e5c-74d9-4ca7-973c-23125dc2372a',
                        'e3e6540b-4801-444b-9122-83db40735d0d',
                    ],
                    color: theme.colors.subjectGreen,
                },
                {
                    title: t('homepage/subjectChemistry'),
                    icon: <ChemistrySubjectIcon />,
                    knowUuids: [
                        '921c6b68-891e-427d-ac89-1bd5310a5bb8',
                        '1a446de0-535e-409a-b981-cff53bd59174',
                        'e026a7e3-4eee-45d0-bf9c-6d117fff9ec1',
                    ],
                    color: theme.colors.subjectGreen,
                },
                {
                    title: t('homepage/subjectEnglish'),
                    icon: <EnglishSubjectIcon />,
                    knowUuids: [
                        '46d1058b-f6e0-46de-98a5-ddd174160bb4',
                        'a058e275-06c9-4cf7-b21b-e038ba388e24',
                        'ad717b92-763e-46fc-a0ff-142258b874d9',
                    ],
                    color: theme.colors.subjectBlue,
                },
                {
                    title: t('homepage/subjectHistory'),
                    icon: <HistorySubjectIcon />,
                    knowUuids: [
                        '52198792-bbf4-4eb0-892e-b24abd8589d5',
                        'd4cb9263-dd9c-42a2-870d-71d553f65bb3',
                        'a650e981-8e2e-49dc-9326-9134e104e536',
                    ],
                    color: theme.colors.subjectYellow,
                },
                {
                    title: t('homepage/subjectBiology'),
                    icon: <BiologySubjectIcon />,
                    knowUuids: [
                        '30bcf205-34ee-449b-af28-c5a6247c240e',
                        '45a99ab2-cfdb-4f78-be6e-164e3d6d0a88',
                        '1a9be2ce-7ce2-4393-a90f-54421bd0d3b6',
                    ],
                    color: theme.colors.subjectGreen,
                },
            ]
        case Language.Italian:
            return [
                {
                    title: t('homepage/subjectBiology'),
                    icon: <BiologySubjectIcon />,
                    knowUuids: [
                        '29645a71-4343-4623-afb7-65fa9b03cd31',
                        '1214bfa5-64a2-42bf-88e4-92a19d32fd90',
                        '1b132c71-ee4d-4988-9bdd-d81d68a5b53e',
                    ],
                    color: theme.colors.subjectGreen,
                },
                {
                    title: t('homepage/subjectMath'),
                    icon: <MathSubjectIcon />,
                    knowUuids: [
                        '8baa67d7-9de8-44fc-bfd7-ded8c66b4bf1',
                        'ff63bb83-7c02-47a5-b246-c43c2afb0b6d',
                        '050a51a7-c924-4b81-a032-5e6aa14bbae8',
                    ],
                    color: theme.colors.subjectGreen,
                },
                {
                    title: t('homepage/subjectChemistry'),
                    icon: <ChemistrySubjectIcon />,
                    knowUuids: [
                        '7ae78003-4ca3-4be6-8fd4-56698972e0ce',
                        '985f77db-05ee-4a7e-a1ea-11fc09d11a5e',
                        '3f1feb97-e3c8-465c-8905-40682222afb1',
                    ],
                    color: theme.colors.subjectGreen,
                },
                {
                    title: t('homepage/subjectEnglish'),
                    icon: <EnglishSubjectIcon />,
                    knowUuids: [
                        '1ca0cf55-9644-4760-93ad-682a98e00fa6',
                        '11b23ea2-57cf-48f3-8969-0f6bef76ab30',
                        '423efc56-0e5b-4dc0-b7b1-a8a16108fe65',
                    ],
                    color: theme.colors.subjectBlue,
                },
                {
                    title: t('homepage/subjectHistory'),
                    icon: <HistorySubjectIcon />,
                    knowUuids: [
                        '87047d4a-50d4-4a2d-8cdf-3fc43525c3fc',
                        '32142cfb-6adc-43ca-a3cf-9ffbc5a13076',
                        '9b33e90d-e955-4431-9b27-6cd29af56abd',
                    ],
                    color: theme.colors.subjectYellow,
                },
            ]

        case Language.ColombianSpanish:
            return [
                {
                    title: t('homepage/subjectBiology'),
                    icon: <BiologySubjectIcon />,
                    knowUuids: [
                        'a0c28bf8-8c05-446a-9bcd-23a99740976d',
                        'fba60af3-a23c-4964-800b-7a6f4e5e6240',
                        '5e7841a2-470f-4f11-a137-db83bdd2c077',
                    ],
                    color: theme.colors.subjectGreen,
                },
                {
                    title: t('homepage/subjectMath'),
                    icon: <MathSubjectIcon />,
                    knowUuids: [
                        '909863d1-3f04-498d-95e8-ca8ed203ff11',
                        '9e53ae0f-d6e6-4eac-b982-651d3393838d',
                        'a6a65744-de9b-4212-983b-8db8b0a01350',
                    ],
                    color: theme.colors.subjectGreen,
                },
                {
                    title: t('homepage/subjectChemistry'),
                    icon: <ChemistrySubjectIcon />,
                    knowUuids: [
                        'd481192a-03ad-45ed-a3a1-e973271fccb5',
                        '4445c943-6e34-4570-b7dd-cdd29e369896',
                        '1e68f827-68ec-47d9-94d1-ef5276ae007e',
                    ],
                    color: theme.colors.subjectGreen,
                },
                {
                    title: t('homepage/subjectEnglish'),
                    icon: <EnglishSubjectIcon />,
                    knowUuids: [
                        '3431ce6d-7c6c-4c53-b207-43ce99129622',
                        'a901493c-9718-4539-8b80-a1e185ed83e2',
                        'ef347c9d-fa2e-4248-b33e-ba39c3d9d684',
                    ],
                    color: theme.colors.subjectBlue,
                },
                // {
                //     title: t('homepage/subjectHistory'),
                //     icon: <HistorySubjectIcon />,
                //     knowUuids: [
                //         '87047d4a-50d4-4a2d-8cdf-3fc43525c3fc',
                //         '32142cfb-6adc-43ca-a3cf-9ffbc5a13076',
                //         '9b33e90d-e955-4431-9b27-6cd29af56abd',
                //     ],
                //     color: theme.colors.subjectYellow,
                // },
            ]
        case Language.Spanish:
        case Language.ArgentineSpanish:
        case Language.ChileanSpanish:
        case Language.PeruvianSpanish:
        case Language.MexicanSpanish:
        case Language.Greek:
        case Language.Romanian:
            return []
        case Language.Turkish:
            return [
                {
                    title: t('homepage/subjectBiology'),
                    icon: <BiologySubjectIcon />,
                    knowUuids: [
                        '1945ccfc-bd4d-432c-9e4c-e250b683079d',
                        'a1b80e85-f10d-4c15-ac07-5a5b766ab42d',
                        '16b86293-f36c-4241-bed3-1bdb6eafd43b',
                    ],
                    color: theme.colors.subjectGreen,
                },
                {
                    title: t('homepage/subjectMath'),
                    icon: <MathSubjectIcon />,
                    knowUuids: [
                        '7098f41f-2e06-42fb-b86f-13b8eb265389',
                        '70f1ff6c-94a6-45db-80cb-11df54aa568a',
                        '9ab22766-5639-4997-800f-1b7b0367aca8',
                    ],
                    color: theme.colors.subjectGreen,
                },
                {
                    title: t('homepage/subjectChemistry'),
                    icon: <ChemistrySubjectIcon />,
                    knowUuids: [
                        'e070a92b-bc7a-4d22-8080-f042ce4e66a2',
                        'd7bac11b-c20e-43c8-b9b7-e7ff460489d1',
                        '7aca424a-4179-45ab-ad86-814b0707d18c',
                    ],
                    color: theme.colors.subjectGreen,
                },
                {
                    title: t('homepage/subjectTurkish'),
                    icon: <WorldSubjectIcon />,
                    knowUuids: [
                        '81a525fa-87fc-47f7-9b60-5d266cad2e71',
                        'e7bb572d-b471-4c3b-8d7c-0cdd0e7d2916',
                        '98572ea0-a562-49c8-8720-c536625b5b53',
                    ],
                    color: theme.colors.subjectBlue,
                },
                {
                    title: t('homepage/subjectHistory'),
                    icon: <HistorySubjectIcon />,
                    knowUuids: [
                        'ef33f8a6-7d2d-43e5-b86c-a58451887332',
                        '876c46dc-3c30-47d7-b6da-daf66b85ffd0',
                        'd117485b-4ca4-495d-ba94-cda0217060af',
                    ],
                    color: theme.colors.subjectYellow,
                },
            ]
        default:
            throw new Error(`Unknown language: ${contentLanguage}`)
    }
}

export const getInfluencerVideos = (locale: Language) => {
    switch (locale) {
        case Language.German:
            return [
                {
                    subtitle: 'Ahmad',
                    isDark: true,
                    image: AhmadScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/de/testimonial-ahmad.mp4',
                },
                {
                    subtitle: 'Tim Francis',
                    isDark: true,
                    image: TimScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/de/testimonial-tim-francis-1.mp4',
                },
                {
                    subtitle: 'Staufitpaula',
                    isDark: true,
                    image: PaulaScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/de/testimonial-staufitpaula.mp4',
                },
                {
                    subtitle: 'Buniwrld',
                    isDark: false,
                    image: BuniworldScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/de/testimonial-buniwrld.mp4',
                },
            ]
        case Language.French:
            return [
                {
                    subtitle: 'studywithme008',
                    isDark: true,
                    image: StudyWithMeScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/fr/testimonial-2.mp4',
                },
            ]
        case Language.Polish:
            return [
                {
                    subtitle: 'Dawid',
                    isDark: false,
                    image: Dawid1Screenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/pl/testimonial-dawid-1.mp4',
                },
                {
                    subtitle: 'Marysia',
                    isDark: true,
                    image: MarysiaScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/pl/testimonial-marysia.mp4',
                },
                {
                    subtitle: 'Oskar',
                    isDark: true,
                    image: OskarScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/pl/testimonial-oskar.mp4',
                },
                {
                    subtitle: 'Kuba',
                    isDark: false,
                    image: KubaScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/pl/testimonial-kuba.mp4',
                },
                {
                    subtitle: 'Dawid',
                    isDark: true,
                    image: Dawid2Screenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/pl/testimonial-dawid-2.mp4',
                },
            ]
        case Language.AmericanEnglish:
            return [
                {
                    subtitle: 'Niki',
                    isDark: true,
                    image: NikiScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/en/testimonial-niki.mp4',
                },
                {
                    subtitle: 'Landry',
                    isDark: true,
                    image: LandryScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/en/testimonial-landry.mp4',
                },
                {
                    subtitle: 'Elise',
                    isDark: true,
                    image: EliseScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/en/testimonial-elise.mp4',
                },
                {
                    subtitle: 'Spencer',
                    isDark: true,
                    image: SpencerScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/en/testimonial-spencer.mp4',
                },
            ]
        case Language.BritishEnglish:
            return [
                {
                    subtitle: 'mrwells_',
                    isDark: true,
                    image: JosephWellsScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/en-GB/testimonial-joseph.mp4',
                },
                {
                    subtitle: 'joshbrownjsy',
                    isDark: true,
                    image: JoshBrownScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/en-GB/testimonial-josh.mp4',
                },
                {
                    subtitle: 'mattgreen.jgm',
                    isDark: true,
                    image: MattGreenScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/en-GB/testimonial-matt.mp4',
                },
                {
                    subtitle: 'steview.knows',
                    isDark: true,
                    image: StevieScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/en-GB/testimonial-steve.mp4',
                },
            ]
        case Language.Italian:
            return [
                {
                    subtitle: 'infotricks',
                    isDark: true,
                    image: InfoTricksScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/it/testimonial-infotricks.mp4',
                },
                {
                    subtitle: 'heyprof',
                    isDark: true,
                    image: HeyProfScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/it/testimonial-heyprof.mp4',
                },
                {
                    subtitle: 'sonoloi',
                    isDark: true,
                    image: SonoloiScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/it/testimonial-sonoloi.mp4',
                },
                {
                    subtitle: 'ddfonline',
                    isDark: true,
                    image: DDFonlineScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/it/testimonial-ddfonline.mp4',
                },
                {
                    subtitle: 'giusyliberato',
                    isDark: true,
                    image: GiusyScreenshot,
                    video: 'https://content-eu-central-1.knowunity.com/static/homepage/it/testimonial-giusyliberato.mp4',
                },
            ]
        case Language.Spanish:
        case Language.ArgentineSpanish:
        case Language.ChileanSpanish:
        case Language.ColombianSpanish:
        case Language.PeruvianSpanish:
        case Language.MexicanSpanish:
        case Language.Turkish:
        case Language.Greek:
        case Language.Romanian:
            return []
        default:
            throw new Error(`Unknown language: ${locale}`)
    }
}

export const getHeroVideo = (locale: Language) => {
    switch (locale) {
        case Language.German:
            return 'https://static.knowunity.com/assets/homepage/de/ai-hero-teaser.mp4'
        case Language.French:
            return 'https://content-eu-central-1.knowunity.com/static/homepage/fr/usp-knows.mp4' //'https://content-eu-central-1.knowunity.com/static/homepage/fr/hero-teaser.mp4'
        case Language.Polish:
            return 'https://content-eu-central-1.knowunity.com/static/homepage/pl/hero-teaser.mp4'
        case Language.BritishEnglish:
        case Language.Greek:
        case Language.Romanian:
            return 'https://content-eu-central-1.knowunity.com/static/homepage/en-GB/hero-teaser.mp4'
        case Language.Turkish:
            return 'https://content-eu-central-1.knowunity.com/static/homepage/tr/hero-teaser.mp4 '
        case Language.AmericanEnglish:
            return 'https://static.knowunity.com/assets/homepage/en/ai-hero-teaser.mp4'
        case Language.Italian:
            return 'https://content-eu-central-1.knowunity.com/static/homepage/it/hero-teaser.mp4'
        case Language.Spanish:
        case Language.ArgentineSpanish:
        case Language.ChileanSpanish:
        case Language.ColombianSpanish:
        case Language.PeruvianSpanish:
        case Language.MexicanSpanish:
            return 'https://content-eu-central-1.knowunity.com/static/homepage/es/hero-teaser.mp4'
        default:
            throw new Error(`Unknown language: ${locale}`)
    }
}

export const getAdvantageVideo = (locale: Language, type: 'knows' | 'ai-tutor') => {
    if (type === 'ai-tutor') {
        switch (locale) {
            case Language.German:
                return 'https://static.knowunity.com/assets/homepage/de/knowunity-ai-hero-teaser.mp4.mp4'
            default:
                return 'https://static.knowunity.com/assets/homepage/en/knowunity-ai-hero-teaser.mp4'
        }
    } else
        switch (locale) {
            case Language.German:
                return `https://content-eu-central-1.knowunity.com/static/homepage/de/usp-${type}.mp4`
            case Language.French:
                return `https://content-eu-central-1.knowunity.com/static/homepage/fr/usp-${type}.mp4`
            case Language.Polish:
                return `https://content-eu-central-1.knowunity.com/static/homepage/pl/usp-${type}.mp4`
            case Language.BritishEnglish:
            case Language.AmericanEnglish:
            case Language.Turkish:
            case Language.Greek:
            case Language.Romanian:
                return `https://content-eu-central-1.knowunity.com/static/homepage/en-GB/usp-${type}.mp4`
            case Language.Italian:
                return `https://content-eu-central-1.knowunity.com/static/homepage/it/usp-${type}.mp4`
            case Language.Spanish:
            case Language.ArgentineSpanish:
            case Language.ChileanSpanish:
            case Language.ColombianSpanish:
            case Language.PeruvianSpanish:
            case Language.MexicanSpanish:
                return `https://content-eu-central-1.knowunity.com/static/homepage/es/usp-${type}.mp4`
            default:
                throw new Error(`Unknown language: ${locale}`)
        }
}

export const getBecomeKnowerVideo = (language: Language) => {
    switch (language) {
        case Language.German:
            return `https://content-eu-central-1.knowunity.com/static/homepage/de/knower-content.mp4`
        case Language.French:
        case Language.Polish:
        case Language.Italian:
        case Language.BritishEnglish:
        case Language.AmericanEnglish:
        case Language.Spanish:
        case Language.ArgentineSpanish:
        case Language.ChileanSpanish:
        case Language.ColombianSpanish:
        case Language.PeruvianSpanish:
        case Language.Turkish:
        case Language.MexicanSpanish:
        case Language.Greek:
        case Language.Romanian:
            return null
        default:
            throw new Error(`Unknown language: ${language}`)
    }
}
