import { CountryCode } from 'interfaces/Country'
import { Language } from 'interfaces/Language'

export function languageToCountryCode(language: Language) {
    switch (language) {
        case Language.German:
            return CountryCode.Germany
        case Language.French:
            return CountryCode.France
        case Language.Polish:
            return CountryCode.Poland
        case Language.Italian:
            return CountryCode.Italy
        case Language.Spanish:
            return CountryCode.Spain
        case Language.BritishEnglish:
            return CountryCode.UnitedKingdom
        case Language.AmericanEnglish:
            return CountryCode.UnitedStates
        case Language.ColombianSpanish:
            return CountryCode.Colombia
        case Language.Turkish:
            return CountryCode.Turkey
        case Language.MexicanSpanish:
            return CountryCode.Mexico
        case Language.ArgentineSpanish:
            return CountryCode.Argentina
        case Language.ChileanSpanish:
            return CountryCode.Chile
        case Language.PeruvianSpanish:
            return CountryCode.Peru
        case Language.Romanian:
            return CountryCode.Romania
        case Language.Greek:
            return CountryCode.Greece
    }
    throw new Error(`Unknown language ${language}`)
}
