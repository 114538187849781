import { UAParser } from 'ua-parser-js'
import {
    storeDeviceIdentifier as storeDeviceIdentifierStorage,
    getDeviceIdentifier as getDeviceIdentifierStorage,
} from 'lib/tracking/storage'
import { uuidv4 } from 'lib/tracking/attribution/uuid'

export interface DeviceInformation {
    formFactor: 'phone' | 'tablet' | 'desktop' | null
    uuid: string | null
    os: string | undefined
    osVersion: string | undefined
    screenSize: number | null
}

let cachedDeviceInformation: DeviceInformation | null = null

export function getDeviceInformation(): DeviceInformation | null {
    if (cachedDeviceInformation) {
        return cachedDeviceInformation
    }

    const isServerSide = typeof window === 'undefined'
    const parser = UAParser()
    let screenSize: number | null = null

    if (!isServerSide) {
        const { width, height } = window.screen
        screenSize = getDiagonalLength(width, height)
    }

    let formFactor: 'phone' | 'tablet' | 'desktop' | null = null
    if (parser.device.type === 'mobile') {
        formFactor = 'phone'
    } else if (parser.device.type === 'tablet') {
        formFactor = 'tablet'
    } else if (parser.device.type === undefined) {
        formFactor = 'desktop'
    }

    const information: DeviceInformation = {
        formFactor,
        uuid: isServerSide ? null : getDeviceIdentifier(),
        os: parser.os.name?.toLowerCase(),
        osVersion: parser.os.version,
        screenSize: screenSize,
    }

    if (!isServerSide) {
        cachedDeviceInformation = information
    }

    return information
}

const getDiagonalLength = (width: number, height: number) => Math.floor(Math.sqrt(width * width + height * height))

export function isIOS() {
    if (typeof window !== 'undefined') {
        return (
            ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
                navigator.platform
            ) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        )
    } else {
        return false
    }
}

export function isMobile(userAgentSource?: string) {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
        /Opera Mini/i,
        /IEMobile/i,
        /WPDesktop/i,
    ]

    const source = userAgentSource ?? navigator.userAgent

    return toMatch.some((toMatchItem) => {
        return source.match(toMatchItem)
    })
}

let cachedDeviceIdentifier: string | null = null

export function getDeviceIdentifier() {
    if (cachedDeviceIdentifier) {
        return cachedDeviceIdentifier
    }

    let value = getDeviceIdentifierStorage()
    if (!value) {
        value = uuidv4()
        storeDeviceIdentifierStorage(value)
    }

    cachedDeviceIdentifier = value
    return value
}

export const getMobileOS = () => {
    if (typeof window === 'undefined') {
        return null
    }

    if (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator?.userAgent?.includes('Mac') && 'ontouchend' in document)
    ) {
        return 'ios'
    }

    if (navigator?.userAgent?.toLowerCase()?.includes('android')) {
        return 'android'
    }

    return null
}
