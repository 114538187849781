import { useEffect, useState } from 'react'
import styled from 'styled-components'
import theme from 'stories/utils/theme'
import { ButtonText, Headline2, Headline4 } from 'stories/elements/Typography/Text'
import KnowFrameWebp from 'assets/homepage/know_frame.webp'
import AiFrameWebp from 'assets/homepage/ai_tutor_frame.webp'
import SmallAiFrameWebp from 'assets/homepage/small_ai_tutor_frame.webp'
import SmallKnowFrameWebp from 'assets/homepage/small_know_frame.webp'
import VideoPlayer from 'components/modules/Players/VideoPlayer'
import { useRouter } from 'next/router'
import NewButton, { NewButtonLink } from 'components/elements/NewButton'
import { redirectToAppDownloadUrlAsync } from 'lib/features/app-links'
import AppleIcon from 'assets/apple.svg'
import GoogleIcon from 'assets/google.svg'
import { aiUsageRoute, knowCreateOptionsRoute } from 'lib/constants/routes'
import { getAdvantageVideo } from 'lib/constants/homepage'
import NextImage from 'components/elements/NextImage'
import { parseLocale } from 'lib/constants/localization'
import _useTranslation from 'lib/hooks/_useTranslation'
import { getCountryLanguageConfig } from 'lib/constants/countryLanguageConfig'
import { isIOS } from 'lib/tracking/attribution/device'

const Container = styled.div`
    width: 100%;
    min-height: 800px;
    height: 125vw;
    background-color: ${theme.colors.snowWhite};
    position: relative;
    display: grid;

    padding-top: 50px;

    @media (max-width: 1680px) {
        height: 165vw;
    }
    @media (max-width: 1440px) {
        height: 175vw;
    }
    @media (max-width: 1250px) {
        height: 200vw;
    }
    @media (max-width: 1050px) {
        height: 225vw;
    }
    @media (max-width: 750px) {
        overflow: hidden;

        .light {
            display: none;
        }

        height: 800px;
        grid-template-columns: 1fr;
        place-content: start center;
        padding-top: 0px;
    }
`

const AdvantagesWrapper = styled.div`
    top: 0;
    padding: 0 5%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 250px;

    will-change: transform;
    transform: translate3d(0vw, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;

    @media (max-width: 1250px) {
        grid-gap: 100px;
    }
    @media (max-width: 750px) {
        grid-gap: 0px;
        padding: 0;
        grid-template-columns: 1fr;
    }
`

const AdvantageCard = styled.div<{ backgroundColor: string }>`
    display: grid;
    height: 80vh;
    width: 90vw;
    overflow: hidden;
    min-height: 600px !important;
    grid-template-columns: 1fr 1fr;
    background-color: ${({ backgroundColor }) => backgroundColor ?? theme.colors.deepSeaBlue};
    border-radius: ${theme.borderRadius.huge};
    padding: 0px;
    span {
        display: block;
    }
    .text-section {
        display: flex;
        padding-left: 10%;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        gap: 20px;
    }
    .image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        .phone {
            z-index: 3;
        }
    }
    button {
        display: flex;
        justify-content: start;
    }

    .frame {
        position: absolute;
        z-index: 2;
    }
    h2 {
        width: 75%;
    }

    @media (max-width: 1440px) {
        h2 {
            width: 100%;
        }
        grid-template-columns: 1fr 2fr;
    }
    @media (max-width: 1050px) {
        grid-template-columns: 1fr 1fr;
        .frame {
            display: none;
        }
    }
    @media (max-width: 750px) {
        width: 100vw;
        height: 800px;
        display: flex;
        border-radius: 0;
        flex-direction: column;
        place-content: start;
        grid-template-columns: 1fr;
        align-items: center;
        overflow: hidden;
        padding: 15px 10px;
        .text-section {
            gap: 0px;
            width: 100%;
        }
        .frame {
            display: inline-block;
            bottom: 0;
            width: 330px;
            height: 375px;
        }
    }
`

const FloatingButtonWrapper = styled.div`
    z-index: ${theme.zIndex.FAB};
`

const Camera = styled.div`
    @media (min-width: 750px) {
        width: 100%;
        height: 100vh;
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
        z-index: 50;
        overflow: hidden;
    }
    overflow: auto;
`
interface Props {
    isMobile?: boolean
    type?: 'know' | 'ai-tutor'
}

const AdvantagesComponent = ({ isMobile, type }: Props) => {
    const [scrollPosition, setScrollPosition] = useState(0)
    const { t } = _useTranslation('homepage')
    const { t: tCommon } = _useTranslation('common')
    const router = useRouter()
    const parsedLocale = parseLocale(router.locale)
    const countryLanguageConfig = getCountryLanguageConfig(router.locale)
    const isLaunched = countryLanguageConfig?.isLaunched
    const showFloatingButton = scrollPosition > 700
    const isApple = isIOS()

    const redirectToUsExpansionPage = () => {
        router.push(knowCreateOptionsRoute({ referrerScreen: 'homepage' }))
    }

    // paralax scrolling
    useEffect(() => {
        const background = document.getElementById('horizontal-scroll')!

        const listenToScroll = () => {
            const offset = 100
            background.style.transform = `translate3d(${offset - window.scrollY * 0.1}vw, 0px, 0px)`
        }

        window.addEventListener('scroll', listenToScroll)
        listenToScroll()
        return () => {
            window.removeEventListener('scroll', listenToScroll)
        }
    }, [])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const listenToSnapScroll = (event: any) => {
        setScrollPosition(event.target.scrollTop)
    }

    useEffect(() => {
        const snapScroll = document.querySelector('#snap-scroll')
        snapScroll && snapScroll.addEventListener('scroll', listenToSnapScroll)
        return () => {
            snapScroll && snapScroll.removeEventListener('scroll', listenToSnapScroll)
        }
    }, [])

    let advantagesData = [
        {
            frameWebp: AiFrameWebp,
            source: getAdvantageVideo(parsedLocale.interfaceLanguageCode, 'ai-tutor') ?? '',
            smallFrameWebp: SmallAiFrameWebp,
            text: t('homepage/advantageTitle4'),
            title: t('homepage/advantageText4'),
            type: 'ai-tutor',
            color: theme.colors.primitiveYellow500,
            titleColor: theme.colors.contentSecondary,
            textColor: theme.colors.headlineDark,
            isGIF: !!getAdvantageVideo(parsedLocale.interfaceLanguageCode, 'ai-tutor')?.endsWith('gif'),
        },
        {
            frameWebp: KnowFrameWebp,
            source: getAdvantageVideo(parsedLocale.interfaceLanguageCode, 'knows') ?? '',
            smallFrameWebp: SmallKnowFrameWebp,
            text: t('homepage/advantageText1'),
            title: t('homepage/advantageTitle1'),
            type: 'know',
            color: theme.colors.forestGreen,
            titleColor: 'rgba(255, 255, 255, 0.7)',
            textColor: theme.colors.white,
            isGIF: !!getAdvantageVideo(parsedLocale.interfaceLanguageCode, 'knows')?.endsWith('gif'),
        },
    ]

    if (isMobile && type) {
        advantagesData = advantagesData.filter((x) => x.type === type)
    }

    const onDownloadClick = (type: 'ai-tutor' | 'know') => {
        if (type === 'ai-tutor') {
            router.push(aiUsageRoute)
        } else {
            redirectToAppDownloadUrlAsync('dynamic', 'homepage-advantage-section')
        }
    }

    return (
        <Container>
            <Camera>
                <div className="light" />
                <AdvantagesWrapper id="horizontal-scroll">
                    {advantagesData.map((data, i) => (
                        <AdvantageCard key={`advantage-${i}`} backgroundColor={data.color}>
                            <div className="text-section">
                                <Headline4 textAlign="left" color={data.titleColor}>
                                    {data.text}
                                </Headline4>
                                <Headline2 color={data.textColor} marginBottom={isMobile ? 50 : 70} textAlign="left">
                                    {data.title}
                                </Headline2>
                                {!isMobile ? (
                                    <>
                                        {!countryLanguageConfig?.isLaunched ? (
                                            <NewButtonLink
                                                href={knowCreateOptionsRoute({ referrerScreen: 'homepage' })}
                                                width={240}
                                                height={50}
                                                borderRadius={theme.borderRadius.normal}
                                                color={theme.colors.white}
                                            >
                                                <ButtonText textAlign="center" color={theme.colors.accentBlue}>
                                                    {t('homepage/expansionUploadCTA')}
                                                </ButtonText>
                                            </NewButtonLink>
                                        ) : (
                                            <NewButton
                                                onClick={() => onDownloadClick(data.type as 'ai-tutor' | 'know')}
                                                width={270}
                                                height={50}
                                                borderRadius={theme.borderRadius.normal}
                                                color={theme.colors.white}
                                            >
                                                <ButtonText color={theme.colors.accentBlue}>
                                                    {data.type === 'ai-tutor'
                                                        ? tCommon('common/learnMore')
                                                        : t('homepage/conversionCTAButton')}
                                                </ButtonText>
                                            </NewButton>
                                        )}
                                    </>
                                ) : null}
                            </div>
                            <div className="image">
                                <VideoPlayer
                                    width={!isMobile ? 292 : 235.5}
                                    height={!isMobile ? 558 : 450}
                                    autoPlay
                                    loop
                                    muted
                                    objectCover
                                    sourceType="video/mp4"
                                    border={`5px solid ${theme.colors.white}`}
                                    source={data.source}
                                    isGIF={data.isGIF}
                                />

                                {isMobile ? (
                                    <div className="frame">
                                        {data.smallFrameWebp ? (
                                            <NextImage
                                                src={data.smallFrameWebp.src}
                                                height={data.smallFrameWebp.height}
                                                width={data.smallFrameWebp.width}
                                                alt="Mobile know frame background"
                                                lazyLoading
                                            />
                                        ) : null}
                                    </div>
                                ) : (
                                    <div className="frame">
                                        <NextImage
                                            src={data.frameWebp.src}
                                            height={data.frameWebp.height}
                                            width={data.frameWebp.width}
                                            alt="Know frame background"
                                            lazyLoading
                                        />
                                    </div>
                                )}
                            </div>
                        </AdvantageCard>
                    ))}
                </AdvantagesWrapper>
            </Camera>
            {isMobile && showFloatingButton ? (
                <FloatingButtonWrapper>
                    <NewButton
                        fullWidth
                        height={50}
                        borderRadius={theme.borderRadius.normal}
                        onClick={() =>
                            isLaunched ? redirectToAppDownloadUrlAsync('dynamic', 'homepage') : redirectToUsExpansionPage()
                        }
                        fixedBottom
                        icon={isLaunched ? isApple ? <AppleIcon /> : <GoogleIcon /> : <></>}
                    >
                        <ButtonText color={theme.colors.white}>
                            {isLaunched ? t('homepage/videoSectionMobileCTA') : t('homepage/expansionUploadCTA')}
                        </ButtonText>
                    </NewButton>
                </FloatingButtonWrapper>
            ) : null}
        </Container>
    )
}

export default AdvantagesComponent
