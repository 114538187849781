import { User } from 'interfaces/User'
import UserService from 'services/UserService'

export const getUTCTimezoneOffset = (o?: { showAsDifference?: boolean }) =>
    (new Date().getTimezoneOffset() / 60) * (o?.showAsDifference ? -1 : 1)

export const handleUserTimezone = ({ utcTimeOffset, uuid }: User) => {
    const timezoneOffset = getUTCTimezoneOffset()
    if (utcTimeOffset === timezoneOffset) return

    UserService.trackUserTimezone(uuid, timezoneOffset).catch((error) => {
        console.error(error)
    })
}
