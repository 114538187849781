/* eslint-disable no-case-declarations */
import axios, { AxiosRequestConfig } from 'axios'
import store from '../redux/store'
import { toast } from 'react-toastify'
import { ErrorTypes } from 'lib/constants/ErrorTypes'
import config from 'lib/config'
import { TFunction } from 'i18next'
import TokenService from './TokenService'
import { getMediaHeader } from 'lib/tracking/api-media-header'
import { getDeviceIdentifier } from 'lib/tracking/attribution/device'

let isGettingRefreshToken = false
let gettingRefreshTokenFailed = false

// Backend-Edge
const baseURL = config.getApiBaseUrl()
const backendEdge = axios.create({ baseURL })
const backendEdgeWithoutToken = axios.create({ baseURL })

// Global-Auth-Service
const globalAuthUrl = config.getGlobalAuthenticationBaseUrl()
const globalAuthService = axios.create({ baseURL: globalAuthUrl })
const globalAuthServiceWithoutToken = axios.create({ baseURL: globalAuthUrl })

type apiErrorHandlerTranslationKey =
    | 'common/apiErrorHandlerInternetConnectionError'
    | 'common/apiErrorHandlerErrorOccurredError'
    | 'common/apiErrorHandlerServerError'
    | 'common/apiErrorHandlerTimeoutError'
    | 'common/apiErrorHandlerServerNotReachableError'
    | 'common/apiErrorHandlerAccessDeniedError'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addAccessBearerTokenToConfig = (config: AxiosRequestConfig<any>) => {
    if (typeof window === 'undefined') {
        return config
    }

    const token = store().getState().oauthToken
    if (token) {
        if (config.headers !== undefined) {
            config.headers['Authorization'] = `Bearer ${token.accessToken}`
        }
    }

    return config
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addHeadersToRequest = (config: AxiosRequestConfig<any>, addToken: boolean) => {
    if (config.headers !== undefined) {
        config.headers['X-Platform'] = 'web'

        const xMediaHeader = 'aideM-X'.split('').reverse().join('')
        config.headers[xMediaHeader] = getMediaHeader(config)

        if (typeof window !== 'undefined') {
            config.headers['X-Device-UUID'] = getDeviceIdentifier()
        }
    }

    if (addToken) {
        config = addAccessBearerTokenToConfig(config)
    }

    return config
}

backendEdge.interceptors.request.use((config) => {
    return addHeadersToRequest(config, true)
})

backendEdgeWithoutToken.interceptors.request.use((config) => {
    return addHeadersToRequest(config, false)
})

globalAuthService.interceptors.request.use((config) => {
    return addHeadersToRequest(config, true)
})

globalAuthServiceWithoutToken.interceptors.request.use((config) => {
    return addHeadersToRequest(config, false)
})

export class ApiError extends Error {
    hasAlreadyBeenHandled: boolean
    type: ErrorTypes
    data: Record<string, unknown>
    alreadyHandledMessage?: apiErrorHandlerTranslationKey

    // eslint-disable-next-line
    constructor(error: any, hasAlreadyBeenHandled: boolean, alreadyHandledMessage?: apiErrorHandlerTranslationKey) {
        super(error)
        this.name = 'ApiError'
        if (error?.response && error.response.data && error.response.data.error && error.response.data.error.type) {
            this.type = error.response.data.error.type
            this.data = error.response.data.error.data
        } else if (error?.response && error.response.config.responseType === 'blob') {
            switch (error.response.status) {
                case 401:
                    this.type = ErrorTypes.Authorization
                    break
                case 404:
                    this.type = ErrorTypes.NotFound
                    break
                default:
                    this.type = ErrorTypes.NoErrorType
            }
            this.data = {}
        } else {
            this.type = ErrorTypes.NoErrorType
            this.data = {}
        }
        this.hasAlreadyBeenHandled = hasAlreadyBeenHandled
        this.alreadyHandledMessage = alreadyHandledMessage
    }

    handleUnknownError(t: TFunction<'common'>, requestName: string) {
        if (this.hasAlreadyBeenHandled) {
            if (this.alreadyHandledMessage) {
                toast.error(`${t(this.alreadyHandledMessage)} (${this.type})`)
            }
            return
        }

        toast.error(`${t('common/unknownError')} (${this.type})`)
        console.error(`An unknown error occurred during request: ${requestName}, ${this.type}`)
    }
}

// eslint-disable-next-line
export const errorHandler = (error: any) => {
    const isNetworkError = !!error.isAxiosError && !error.response
    if (isNetworkError) {
        return Promise.reject(new ApiError(error, true, 'common/apiErrorHandlerInternetConnectionError'))
    }

    const originalRequest = error.config

    switch (error.response.status) {
        case 401:
            const url = error.response.config.url
            const errorType = error.response?.data?.error?.type
            if (url !== 'oauth/token' && errorType === 'AuthorizationError' && !originalRequest._retry) {
                if (!isGettingRefreshToken) {
                    isGettingRefreshToken = true
                    originalRequest._retry = true
                    console.log('Refreshing token')
                    return TokenService.refreshToken()
                        .then(() => {
                            isGettingRefreshToken = false
                            gettingRefreshTokenFailed = false
                            return backendEdge(originalRequest)
                        })
                        .catch((error) => {
                            let alreadyHandledMessage: apiErrorHandlerTranslationKey | undefined
                            console.error('Cannot refresh token.', error)
                            isGettingRefreshToken = false
                            gettingRefreshTokenFailed = true
                            if (error instanceof ApiError) {
                                if (error.type === ErrorTypes.Authorization) {
                                    TokenService.logout()
                                } else {
                                    alreadyHandledMessage = 'common/apiErrorHandlerErrorOccurredError'
                                }
                            }
                            return Promise.reject(new ApiError(error, true, alreadyHandledMessage))
                        })
                } else {
                    // Prevent firing several token refresh requests at the same time
                    return new Promise((resolve, reject) => {
                        const interval = setInterval(() => {
                            if (!isGettingRefreshToken) {
                                clearInterval(interval)
                                return gettingRefreshTokenFailed ? reject : resolve(null)
                            }
                        }, 200)
                    })
                        .then(() => {
                            return backendEdge(originalRequest)
                        })
                        .catch((error) => {
                            console.error('An error occurred', error)
                        })
                }
            } else {
                return Promise.reject(new ApiError(error, false))
            }

        case 500:
            return Promise.reject(new ApiError(error, true, 'common/apiErrorHandlerServerError'))

        case 504:
            return Promise.reject(new ApiError(error, true, 'common/apiErrorHandlerTimeoutError'))

        case 502:
            return Promise.reject(new ApiError(error, true, 'common/apiErrorHandlerServerNotReachableError'))

        default:
            return Promise.reject(new ApiError(error, false))
    }
}

backendEdge.interceptors.response.use((response) => response, errorHandler)
backendEdgeWithoutToken.interceptors.response.use((response) => response, errorHandler)
globalAuthService.interceptors.response.use((response) => response, errorHandler)
globalAuthServiceWithoutToken.interceptors.response.use((response) => response, errorHandler)

export const GlobalAuthServiceWithoutToken = globalAuthServiceWithoutToken
export const GlobalAuthService = globalAuthService
export const ApiServiceWithoutToken = backendEdgeWithoutToken
export default backendEdge
