export const addQueryParam = (key: string, value: string) => {
    const url = new URL(window.location.href)
    url.searchParams.set(key, value)
    window.history.pushState({}, '', url.toString())
}

export const removeQueryParam = (key: string) => {
    const url = new URL(window.location.href)
    url.searchParams.delete(key)
    window.history.replaceState({}, '', url.toString())
}

export const getQueryParam = (key: string) => {
    const url = new URL(window.location.href)
    return url.searchParams.get(key)
}

export const generateUrlPath = (basePath: string, query: Record<string, string | undefined>) => {
    const params = Object.entries(query).filter(([_, v]) => !!v)
    const searchParams = new URLSearchParams(params as string[][])
    return `${basePath}${params?.length ? `?${searchParams}` : ''}`
}

export enum URLs {
    JobsWebsite = 'https://jobs.knowunity.com',
    TechAtKnowunity = 'https://jobs.knowunity.com/tech-at-knowunity/',
    LinkedIn = 'https://linkedin.com/company/knowunity',
    plusPlanCalendly = 'https://calendly.com/harry-gurthangeles/knowunity-plus-eltern',
    AdSurvey = 'https://docs.google.com/forms/d/e/1FAIpQLSfMV-pckbffZrxHKrCIKc4XmHkOsQWfvNlUDSZVNOZOKRRIUQ/viewform?usp=sf_link',
    AdobeScanApple = 'https://apps.apple.com/us/app/adobe-scan-pdf-ocr-scanner/id1199564834',
    AdobeScanAndroid = 'https://play.google.com/store/apps/details?id=com.adobe.scan.android&hl=en&gl=US',
    USContentTips = 'https://static.knowunity.com/assets/expansion/content-tips/US.pdf',
    AmbassadorNotionDocument = 'https://www.notion.so/knowunityambassadors/Apply-to-become-a-Knowunity-Ambassador-2b5b66dedc034860950848c0f236b43b',
    b2bCalendly = 'https://knowunity.de/about/calendly?referrerScreen=advertise',
    knowunityArticleHub = 'https://sites.google.com/knowunity.com/knowunity-article-hub/home',
    WhatsappJoinLink = 'https://chat.whatsapp.com/IALiEKgRFwzHaTlHeUKYca',
    MistralPodcast = 'https://podcasts.apple.com/de/podcast/startup-insider/id1511786820?i=1000674384169',
    KnowunityAIPodcast = `https://podcasts.apple.com/de/podcast/knowunity-wie-ki-bildung-global-zugänglich-macht-mit/id1511786820?i=1000678403281`,
}
