import { TFunction } from 'i18next'
import { CountryConfig } from './countryconfig'
import {
    companyRoute,
    seoSubjectsRoute,
    pressRoute,
    blogRoute,
    expansionRoute,
    knowsRoute,
    safetyRoute,
    aiUsageRoute,
} from './routes'
import SubjectsIcon from 'assets/app/sidebar/subject.svg'
import CareerIcon from 'assets/header/career.svg'
import PressIcon from 'assets/header/press.svg'
import BlogIcon from 'assets/header/blog.svg'
import KnowunityAI from 'assets/header/my_ai.svg'
import TechAtKnowunityIcon from 'assets/header/tech.svg'
import MyContentIcon from 'assets/app/sidebar/know_demands.svg'
import HomeIcon from 'assets/app/sidebar/flashcard.svg'
import AddContentIcon from 'assets/app/sidebar/exams.svg'
import Lock from 'assets/icons/lock.svg'
import { URLs } from './URLs'

export enum HeaderCategory {
    Learning = 'LEARNING',
    Company = 'COMPANY',
}

export const getMenuCategories = (countryConfig: CountryConfig, tCommon: TFunction<'common'>, isMobile?: boolean) => [
    {
        category: HeaderCategory.Learning,
        title: tCommon('common/navBarMenuLearningMenu'),
        dropdownItems: [
            {
                name: tCommon('common/allSubjects'),
                to: seoSubjectsRoute,
                show: !countryConfig.hasContentCampaignRunning,
                icon: <SubjectsIcon />,
            },
        ],
    },
    {
        category: HeaderCategory.Company,
        title: tCommon('common/navBarMenuCompaniesMenuItem'),
        dropdownItems: [
            {
                name: tCommon('common/navBarMyKnowsLink'),
                to: knowsRoute(),
                show: countryConfig.hasContentCampaignRunning,
                icon: <HomeIcon />,
            },
            {
                name: tCommon('common/informationLabel'),
                to: expansionRoute,
                show: countryConfig.hasContentCampaignRunning,
                icon: <AddContentIcon />,
            },
            {
                name: tCommon('common/navBarMenuCompaniesForCompaniesMenuItem'),
                to: companyRoute,
                show: countryConfig.hasParentsPages,
                icon: <PressIcon />,
            },
            {
                name: tCommon('common/navBarMenuCompaniesCareersMenuItem'),
                to: URLs.JobsWebsite,
                show: !countryConfig.hasContentCampaignRunning,
                icon: <CareerIcon />,
            },
            {
                name: 'Knowunity AI',
                to: aiUsageRoute,
                show: !countryConfig.hasContentCampaignRunning,
                icon: <KnowunityAI />,
            },
            {
                name: tCommon('common/navBarMenuTechAtKnowunityMenuItem'),
                to: URLs.TechAtKnowunity,
                show: !countryConfig.hasContentCampaignRunning,
                icon: <TechAtKnowunityIcon />,
            },
            {
                name: tCommon('common/navBarMenuCompaniesPressMenuItem'),
                to: pressRoute,
                show: !countryConfig.hasContentCampaignRunning,
                icon: <MyContentIcon />,
            },
            {
                name: 'Blog',
                to: blogRoute,
                show: countryConfig.hasBlog,
                icon: <BlogIcon />,
            },
            {
                name: tCommon('common/safetyHeaderLabel'),
                to: safetyRoute,
                show: true,
                icon: <Lock />,
            },
        ],
    },
]
