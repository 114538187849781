export enum Language {
    German = 'de',
    French = 'fr',
    BritishEnglish = 'en-GB',
    Polish = 'pl',
    Italian = 'it',
    AmericanEnglish = 'en',
    Spanish = 'es',
    Turkish = 'tr',
    MexicanSpanish = 'es-MX',
    ColombianSpanish = 'es-CO',
    PeruvianSpanish = 'es-PE',
    ChileanSpanish = 'es-CL',
    ArgentineSpanish = 'es-AR',
    Romanian = 'ro',
    Greek = 'el',
}

export interface LanguageInterface {
    id: number
    name: string
    code: Language
}

export interface LanguageOption {
    data: LanguageInterface
    label: string
}
