export enum Currency {
    EUR = 'EUR',
    CHF = 'CHF',
    PLN = 'PLN',
    GBP = 'GBP',
    USD = 'USD',
    COP = 'COP',
    TRY = 'TRY',
    MXN = 'MXN',
    PEN = 'PEN',
    CLP = 'CLP',
    ARS = 'ARS',
    RON = 'RON',
}
