import { GetServerSidePropsContext } from 'next'
import { parseCookies, setCookie } from 'nookies'

const ActiveExperimentsKEY = 'ACTIVE_EXPERIMENTS'

export type ActiveExperiments = Record<ExperimentKey, ExperimentVariant>

export enum ExperimentKey {
    test = 'TEST',
    mobileKnowThumbnailAddons = 'MOBILE_KNOW_THUMBNAIL_ADDONS',
    showScrollableBreadcrumbs = 'SHOW_SCROLLABLE_BREADCRUMBS',
    showVisualTrustElements = 'SHOW_VISUAL_TRUST_ELEMENTS',
    showDesktopValuePropositionBanner = 'SHOW_DESKTOP_VALUE_PROPOSITION_BANNER_V1',
    showPDFMobilePreview = 'SHOW_PDF_MOBILE_PREVIEW',
    showDesktopFeatureButtons = 'SHOW_DESKTOP_FEATURE_BUTTONS',
    showPinterestStyleSimilarKnows = 'SHOW_PINTEREST_STYLE_SIMILAR_KNOWS',
}

export enum ExperimentVariant {
    testBaseline = 'TEST_0',
    testVariant = 'TEST_1',
    mobileKnowThumbnailAddonsVariant0 = 'MOBILE_KNOW_THUMBNAIL_ADDONS_VARIANT_V1_0',
    mobileKnowThumbnailAddonsVariant1 = 'MOBILE_KNOW_THUMBNAIL_ADDONS_VARIANT_V1_1',
    mobileKnowThumbnailAddonsVariant2 = 'MOBILE_KNOW_THUMBNAIL_ADDONS_VARIANT_V1_2',
    showScrollableBreadcrumbsVariant0 = 'SHOW_SCROLLABLE_BREADCRUMBS_VARIANT_0',
    showScrollableBreadcrumbsVariant1 = 'SHOW_SCROLLABLE_BREADCRUMBS_VARIANT_1',
    showVisualTrustElementsVariant0 = 'SHOW_VISUAL_TRUST_ELEMENTS_VARIANT_0',
    showVisualTrustElementsVariant1 = 'SHOW_VISUAL_TRUST_ELEMENTS_VARIANT_1',
    showVisualTrustElementsVariant2 = 'SHOW_VISUAL_TRUST_ELEMENTS_VARIANT_2',
    showVisualTrustElementsVariant3 = 'SHOW_VISUAL_TRUST_ELEMENTS_VARIANT_3',
    showDesktopValuePropositionBannerVariant0 = 'SHOW_DESKTOP_VALUE_PROPOSITION_BANNER_V1_VARIANT_0',
    showDesktopValuePropositionBannerVariant1 = 'SHOW_DESKTOP_VALUE_PROPOSITION_BANNER_V1_VARIANT_1',
    showPDFMobilePreviewVariant0 = 'SHOW_PDF_MOBILE_PREVIEW_VARIANT_V1_0',
    showPDFMobilePreviewVariant1 = 'SHOW_PDF_MOBILE_PREVIEW_VARIANT_V1_1',
    showPDFMobilePreviewVariant2 = 'SHOW_PDF_MOBILE_PREVIEW_VARIANT_V1_2',
    showDesktopFeatureButtonsVariant0 = 'SHOW_DESKTOP_FEATURE_BUTTONS_VARIANT_0',
    showDesktopFeatureButtonsVariant1 = 'SHOW_DESKTOP_FEATURE_BUTTONS_VARIANT_1',
    showPinterestStyleSimilarKnowsVariant0 = 'SHOW_PINTEREST_STYLE_SIMILAR_KNOWS_VARIANT_0',
    showPinterestStyleSimilarKnowsVariant1 = 'SHOW_PINTEREST_STYLE_SIMILAR_KNOWS_VARIANT_1', // below 1st page - standard
    showPinterestStyleSimilarKnowsVariant2 = 'SHOW_PINTEREST_STYLE_SIMILAR_KNOWS_VARIANT_2', // below 1st page - dynamic
    showPinterestStyleSimilarKnowsVariant3 = 'SHOW_PINTEREST_STYLE_SIMILAR_KNOWS_VARIANT_3', // below 1st summary - standard
    showPinterestStyleSimilarKnowsVariant4 = 'SHOW_PINTEREST_STYLE_SIMILAR_KNOWS_VARIANT_4', // below 1st summary - dynamic
}

function getRandomVariant(variants: ExperimentVariant[]): ExperimentVariant {
    return variants[Math.floor(Math.random() * variants.length)]
}

function getStoredVariant(storedVariants: string[], variants: ExperimentVariant[]): ExperimentVariant | null {
    for (const variant of variants) {
        if (storedVariants.includes(variant)) {
            return variant
        }
    }

    return null
}

function getExperimentVariant(possibleVariants: ExperimentVariant[], storedVariants: string[]): ExperimentVariant {
    const storedVariant = getStoredVariant(storedVariants, possibleVariants)
    if (storedVariant !== null) {
        return storedVariant
    } else {
        return getRandomVariant(possibleVariants)
    }
}

export function getActiveExperiments(ctx?: GetServerSidePropsContext): ActiveExperiments {
    const storedVariants = getActiveExperimentsStore(ctx)

    const activeExperiments: ActiveExperiments = {
        TEST: getExperimentVariant([ExperimentVariant.testBaseline, ExperimentVariant.testVariant], storedVariants),
        MOBILE_KNOW_THUMBNAIL_ADDONS: getExperimentVariant(
            [
                ExperimentVariant.mobileKnowThumbnailAddonsVariant0,
                ExperimentVariant.mobileKnowThumbnailAddonsVariant1,
                ExperimentVariant.mobileKnowThumbnailAddonsVariant2,
            ],
            storedVariants
        ),
        SHOW_SCROLLABLE_BREADCRUMBS: getExperimentVariant(
            [ExperimentVariant.showScrollableBreadcrumbsVariant0, ExperimentVariant.showScrollableBreadcrumbsVariant1],
            storedVariants
        ),
        SHOW_VISUAL_TRUST_ELEMENTS: getExperimentVariant(
            [
                ExperimentVariant.showVisualTrustElementsVariant0,
                ExperimentVariant.showVisualTrustElementsVariant1,
                ExperimentVariant.showVisualTrustElementsVariant2,
                ExperimentVariant.showVisualTrustElementsVariant3,
            ],
            storedVariants
        ),
        SHOW_DESKTOP_VALUE_PROPOSITION_BANNER_V1: getExperimentVariant(
            [
                ExperimentVariant.showDesktopValuePropositionBannerVariant0,
                ExperimentVariant.showDesktopValuePropositionBannerVariant1,
            ],
            storedVariants
        ),
        SHOW_PDF_MOBILE_PREVIEW: getExperimentVariant(
            [
                ExperimentVariant.showPDFMobilePreviewVariant0,
                ExperimentVariant.showPDFMobilePreviewVariant1,
                ExperimentVariant.showPDFMobilePreviewVariant2,
            ],
            storedVariants
        ),
        SHOW_DESKTOP_FEATURE_BUTTONS: getExperimentVariant(
            [ExperimentVariant.showDesktopFeatureButtonsVariant0, ExperimentVariant.showDesktopFeatureButtonsVariant1],
            storedVariants
        ),
        SHOW_PINTEREST_STYLE_SIMILAR_KNOWS: getExperimentVariant(
            [
                ExperimentVariant.showPinterestStyleSimilarKnowsVariant0,
                ExperimentVariant.showPinterestStyleSimilarKnowsVariant1,
                ExperimentVariant.showPinterestStyleSimilarKnowsVariant2,
                ExperimentVariant.showPinterestStyleSimilarKnowsVariant3,
                ExperimentVariant.showPinterestStyleSimilarKnowsVariant4,
            ],
            storedVariants
        ),
    }

    storeActiveExperiments(Object.values(activeExperiments), ctx)

    return activeExperiments
}

export function getActiveExperimentsVariants() {
    const activeExperiments = getActiveExperiments()
    return Object.values(activeExperiments)
}

function isValidExperiment(experiment: ExperimentVariant, possibleVariants: ExperimentVariant[]): boolean {
    return possibleVariants.includes(experiment)
}

function cachedExperimentsAreValid(cachedExperiments: ActiveExperiments | null): boolean {
    if (!cachedExperiments) return false

    const allPossibleVariants = Object.values(ExperimentVariant)
    return Object.values(cachedExperiments).every((variant) => isValidExperiment(variant, allPossibleVariants))
}

// Active Experiments
export function getActiveExperimentsStore(ctx?: GetServerSidePropsContext): string[] {
    const cookies = parseCookies(ctx)
    const item = cookies[ActiveExperimentsKEY]
    if (!item) {
        return []
    }
    return item.split(',')
}

export const storeActiveExperiments = (experiments: string[], ctx?: GetServerSidePropsContext) => {
    const stringifiedValue = experiments.join(',')
    setCookie(ctx, ActiveExperimentsKEY, stringifiedValue, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
    })
}
