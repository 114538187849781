import { Language } from 'interfaces/Language'
import {
    adsSurveyRedirectRoute,
    appChatRoute,
    appCreateKnowsRoute,
    appEditProfileRoute,
    appFeedsRoute,
    appFlashcardsRoute,
    appLibraryRoute,
    appMyKnowsRoute,
    profilePayoutsCompetitionFormRoutePattern,
    profilePayoutsCompetitionRoutePattern,
    appPayoutsHistoryRoute,
    appPayoutsRequestRoute,
    appPayoutsRoute,
    appSearchRoute,
    appSubjectsKnowsRoute,
    appUnavailableFeatureRoute,
    chatsRedirectRoute,
    learningBotRedirectRoute,
    pollsRedirectRoute,
    proPlanActivateRoute,
    userTopicQuizRedirectRoute,
    usersRedirectRoute,
    studyGuideRoutePattern,
} from './routes'
import { getCookieConsent } from 'lib/tracking/storage'

export function getLandingPageSlugs(locale: Language) {
    switch (locale) {
        case Language.German:
            return [
                'advertise',
                'for-parents',
                'download',
                'pro-plan',
                'checkout',
                'success',
                'press',
                'activate',
                'download',
                'download-know',
                'profile',
                'company',
                'knowunity-ai',
            ]
        case Language.French:
            return [
                'explanation',
                'download',
                'press',
                'pro-plan',
                'success',
                'activate',
                'download-know',
                'profile',
                'knowunity-ai',
            ]
        case Language.Polish:
            return [
                'expansion',
                'explanation',
                'download',
                'press',
                'pro-plan',
                'success',
                'activate',
                'download-know',
                'profile',
                'knowunity-ai',
            ]
        case Language.BritishEnglish:
            return [
                'profile',
                'expansion',
                'explanation',
                'expansion-scotland',
                'download',
                'press',
                'pro-plan',
                'success',
                'activate',
                'download-know',
                'knowunity-ai',
            ]
        case Language.Italian:
            return [
                'expansion',
                'explanation',
                'download',
                'press',
                'pro-plan',
                'success',
                'activate',
                'download-know',
                'profile',
                'knowunity-ai',
            ]
        case Language.AmericanEnglish:
            return [
                'explanation',
                'press',
                'expansion',
                'college-scholarship',
                'download',
                'activate',
                'download-know',
                'profile',
                'knowunity-ai',
            ]
        case Language.Spanish:
        case Language.ArgentineSpanish:
        case Language.ChileanSpanish:
        case Language.ColombianSpanish:
        case Language.PeruvianSpanish:
        case Language.Turkish:
        case Language.MexicanSpanish:
        case Language.Greek:
        case Language.Romanian:
            return ['press', 'download', 'activate', 'download-know', 'profile', 'knowunity-ai']
        default:
            throw new Error(`Unknown language: ${locale}`)
    }
}

export function getPageWithoutMessengerWidget(locale: Language) {
    switch (locale) {
        case Language.German:
        case Language.French:
        case Language.Polish:
        case Language.BritishEnglish:
        case Language.Italian:
        case Language.Greek:
        case Language.Romanian:
            return []
        case Language.Spanish:
        case Language.AmericanEnglish:
        case Language.ArgentineSpanish:
        case Language.ChileanSpanish:
        case Language.ColombianSpanish:
        case Language.PeruvianSpanish:
        case Language.Turkish:
        case Language.MexicanSpanish:
            return ['/knows/[slug]', '/knows/u/[slug]', '/']
        default:
            throw new Error(`Unknown language: ${locale}`)
    }
}

export const isSeoKnowPage = (pathname: string) => {
    const knowPagesPathnames = ['/knows/[slug]', '/knows/u/[slug]']
    return !!knowPagesPathnames.includes(pathname)
}

export function getPagesWithoutNavbarAndFooter() {
    return [
        appFeedsRoute,
        appSearchRoute,
        '/app/knows/[slug]',
        appUnavailableFeatureRoute,
        appMyKnowsRoute,
        appChatRoute,
        appLibraryRoute,
        appFlashcardsRoute,
        appEditProfileRoute,
        appSubjectsKnowsRoute,
        appCreateKnowsRoute,
        appPayoutsRoute,
        appPayoutsHistoryRoute,
        appPayoutsRequestRoute,
        `/app/library/[slug]`,
        proPlanActivateRoute,
        profilePayoutsCompetitionRoutePattern,
        profilePayoutsCompetitionFormRoutePattern,
        // redirects
        learningBotRedirectRoute,
        adsSurveyRedirectRoute,
        pollsRedirectRoute,
        chatsRedirectRoute,
        userTopicQuizRedirectRoute,
        usersRedirectRoute,
    ]
}

export function getPagesWithoutFooter() {
    return [studyGuideRoutePattern]
}

export const shouldEnableClarityForMinorityOfUsers = () => {
    if (typeof window === 'undefined') return false

    const cookieConsent = getCookieConsent()
    if (!cookieConsent?.statistics) {
        return false
    }
    return Math.random() < 0.1
}
