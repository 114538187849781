import { Knower } from './Knower'
import { Grade } from './Grade'
import { Subject } from './Subject'
import { SchoolType } from './SchoolType'
import { User } from './User'
import { ExamBoard } from './ExamBoard'
import { Country } from './Country'
import { LanguageInterface } from './Language'
import { Flashcard, FlashcardRequest } from './Flashcard'

export interface Know {
    uuid: string
    knower: Knower
    title: string
    description: string
    status: KnowStatus
    grades: Grade[]
    subjects: Subject[]
    createdOn: string
    publishedOn: string | null
    inLibrary: boolean
    liked: boolean
    viewedToday: boolean
    knowType: KnowType
    knowerCountry: Country
    contentLanguage: LanguageInterface
    generatedDescription: string | null
    likes: number
    views: number | null
    schoolTypes: SchoolType[]
    commentsCount: number
    slug: string
    isSEOIndexed: boolean
    documents: KnowDocument[]
    thumbnailSmallUrl: string | null
    seoExperimentVariant: 1 | 2 | 3
    totalPageCount: number
    knowFormat: KnowFormat
    thumbnailMediumUrl: string
    thumbnailLargeUrl: string
    examBoards: ExamBoard[] | null
    websitePath: string
    flashcardCount: number
    flashcards: Flashcard[] | null
    localizations: KnowInfoLocalization[] | null
    knowDocumentPages: KnowDocumentPages[]
    seoTitle: string | null
    seoDescription: string | null
}

export type KnowDocumentPages = {
    imageUrl: string
    knowDocumentUuid: string
    knowUuid: string
    pageNumber: number
    uuid: string
}

export type KnowInfoLocalization = {
    description: string
    title: string
    knowType: KnowType
    language: LanguageInterface
}

export enum KnowFormat {
    PDF = 'PDF',
    Audio = 'AUDIO',
    Flashcards = 'FLASHCARDS',
}

export enum KnowStatus {
    Public = 'PUBLIC',
    InReview = 'IN_REVIEW',
    InExtendedReview = 'IN_EXTENDED_REVIEW',
    Draft = 'DRAFT',
    Rejected = 'REJECTED',
    PermanentlyRejected = 'PERMANENTLY_REJECTED',
}

export interface KnowType {
    id: number
    name: string
}

export interface KnowReviewHistory {
    user: User
    status: KnowStatus
    message: string | null
}

export interface KnowDocument {
    uuid: string
    title: string
    contentType: 'PDF'
    orderNumber: number
    previewUrl: string | null
    contentUrl: string
    previewSmallUrl: string | null
    previewMediumUrl: string | null
}

export interface KnowDocumentRequest {
    title: string
    filename: string
    url?: string
}

export interface KnowCreateData {
    title: string
    description: string
    knowType: KnowType
    grades: Grade[]
    subjects: Subject[]
    schoolTypes: SchoolType[]
    documents: KnowDocumentRequest[]
    knowFormat: KnowFormat
    examBoardUuids: string[]
    flashcards: FlashcardRequest[] | null
}

export interface KnowTranscript {
    transcript: string | null
}
