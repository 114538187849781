import { Environment, getEnvironment } from 'lib/config'
import attribution from 'lib/tracking/attribution/attribution'
import { getDeviceIdentifier } from 'lib/tracking/attribution/device'

export const appDownloadUrl = (platform: 'dynamic' | 'android' | 'ios', referrerScreen: string, deepLinkPath?: string) =>
    getAppRedirectLink(platform, referrerScreen, deepLinkPath)

// Redirect to app download after 200 ms. This can be used to track an event before redirecting the user.
export const redirectToAppDownloadUrlAsync = (
    platform: 'dynamic' | 'android' | 'ios',
    referrerScreen: string,
    deepLinkPath?: string
) => {
    setTimeout(() => {
        const url = appDownloadUrl(platform, referrerScreen, deepLinkPath)

        window.location.href = url
    }, 200)
}

export const getAppRedirectLink = (
    platform: 'dynamic' | 'android' | 'ios',
    referrerScreen: string,
    deepLinkPath?: string
) => {
    const { source, sourceCampaign } = attribution.getAttribution()

    const encodedDeepLinkPath = encodeURIComponent(deepLinkPath ?? '')
    const deviceIdentifier = getDeviceIdentifier()

    let redirectPlatform
    switch (platform) {
        case 'dynamic':
            redirectPlatform = 'app'
            break
        case 'ios':
            redirectPlatform = 'ios'
            break
        case 'android':
            redirectPlatform = 'android'
            break
    }

    let domain = 'com'
    if (getEnvironment() === Environment.Dev) {
        domain = 'dev'
    }

    const url = `https://a.knowunity.${domain}/${redirectPlatform}?source=${
        source ?? 'website'
    }&campaign=${sourceCampaign}&path=${encodedDeepLinkPath}&originIdentifier=${deviceIdentifier}&referrerScreen=${referrerScreen}`
    return url
}
