import { CountryCode } from 'interfaces/Country'
import { LanguageOption } from 'interfaces/Language'
import { GroupedCategory, GroupedSubject, Subject } from 'interfaces/Subject'
import { uniqueBy } from './grades'

export const getFirstLocalSubjectFromKnowSubjects = (subjects: Subject[], countryCode: CountryCode) => {
    return subjects.filter((subject) => subject.countryCode === countryCode)[0] ?? subjects[0]
}

export const getGroupedSubjectCategories = (subjects: Subject[]) => {
    const grouped = subjects.reduce((group, subject) => {
        const {
            category: { name: categoryName },
            name: subjectName,
            language,
        } = subject
        group[categoryName] = group[categoryName] ?? []
        group[categoryName].push({ label: subjectName, data: subject, language: language })
        return group
    }, {} as GroupedSubject)

    return Object.entries(grouped).map(([category, subjects]) => ({
        category,
        languages: uniqueBy(
            'id',
            subjects.map((subject) => subject.language)
        ),
        subjects,
    }))
}

const getAllLanguagesOfGroupedCategories = (groupedCategories: GroupedCategory[]) => {
    const langaugesArray: LanguageOption[] = []
    groupedCategories.forEach(({ languages }) => {
        languages.forEach((language) => {
            langaugesArray.push({ data: language, label: language.name })
        })
    })
    return langaugesArray
}

export const getSubjectLanguageOptions = (groupedCategories: GroupedCategory[] | undefined) =>
    groupedCategories?.length ? uniqueBy('label', getAllLanguagesOfGroupedCategories(groupedCategories)) : []

export const showSubjectGrid = (subjectsCount: number) => subjectsCount <= 5

export const showUncollapsedCategories = (subjectsCount: number) => subjectsCount < 10

export const sortSubjects = (subjects: Subject[]) => subjects.sort((a, b) => a.sortId - b.sortId)

export const formatSubjectLabel = ({ name, examType }: Subject) => `${name} ${examType?.showOnKnowCard ? examType.name : ''}`

export const getSubjectColorByCategory = (category: Subject['colorCategory']) => {
    switch (category.toUpperCase()) {
        case 'GREEN':
            return '#0FD246'
        case 'ORANGE':
            return '#FFA401'
        case 'FUCHSIA':
            return '#FF1887'
        case 'YELLOW':
            return '#36D9CF'
        default:
        case 'BLUE':
            return '#1882FF'
    }
}
