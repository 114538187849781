/* eslint-disable @next/next/no-img-element */
import Link from 'next/link'
import { Body1, Body2 } from 'stories/elements/Typography/Text'
import theme from 'stories/utils/theme'
import styled, { css } from 'styled-components'
import Icon from 'assets/icon.png'
import IconDark from 'assets/icon_dark.png'
import SearchBarRedirect from 'components/feature/App/widgets/SearchBarRedirect'
import ProfileIcon from 'assets/header/profile.svg'
import ProfileDarkIcon from 'assets/app/sidebar/profile.svg'
import OpenTheAppDownloadButton from '../SEO/OpenTheAppDownloadButton'
import { trackEvent } from 'lib/tracking/analytics-service'
import {
    AnalyticsEventNavbarOpenAppButtonClick,
    AnalyticsEventProPlanMenuCTAImpression,
} from 'lib/constants/AnalyticsEventType'
import { parseLocale } from 'lib/constants/localization'
import { useRouter } from 'next/router'
import LocaleSwitcher from '../Footer/LocaleSwitcher'
import MobileSearchBar from './MobileSearchBar'
import { aiUsageRoute, proPlanRoute, signInRoute } from 'lib/constants/routes'
import { getCountryLanguageConfig } from 'lib/constants/countryLanguageConfig'
import Row from 'components/elements/Row'
import usePrevious from 'lib/hooks/usePrevious'
import { useState, useEffect, useMemo, useRef } from 'react'
import { ClientOnly } from 'components/elements/ClientOnly'
import { getCountryConfig } from 'lib/constants/countryconfig'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/reducer'
import ProfilePicture from 'components/elements/ProfilePicture'
import UserProfileDropDown from './UserProfileDropDown'
import MenuOption from './MenuOption'
import { getReferrerScreen } from 'lib/formatting/url'
import FloatingTaxonomyMenu from './FloatingTaxonomyMenu'
import HeaderFloatingMenu from './HeaderFloatingMenu'
import HeaderDownloadDesktopButton from './HeaderDownloadDesktopButton'

const Container = styled.header<{ isLandingPage?: boolean; isScrolledDown?: boolean; hideOnScrollDownMobile?: boolean }>`
    position: fixed;
    top: 0;
    width: 100%;
    height: ${theme.heights.navbar};
    z-index: 70; //${theme.zIndex.navBar};
    transition: transform 0.2s;
    padding: 10px 25px;

    @media (max-width: 750px) {
        padding: 10px;
        & .logo img {
            width: 24px;
            height: 24px;
            object-fit: scale-down;
        }
    }
    ${({ isLandingPage }) =>
        isLandingPage
            ? css`
                  ::after {
                      content: '';
                      position: absolute;
                      left: 0;
                      top: 0;
                      right: 0;
                      bottom: -1px;
                      background: rgba(0, 0, 0, 0.2);
                      backdrop-filter: blur(4px);
                      -webkit-mask-image: linear-gradient(
                          to top,
                          rgba(0, 0, 0, 0) 0%,
                          rgb(0, 0, 0) 75.5%,
                          rgb(0, 0, 0) 100%
                      );
                      z-index: -1;
                      pointer-events: none;
                      transform: translateZ(0) !important;
                      backface-visibility: hidden !important;
                      perspective: 1000 !important;
                      -webkit-transform: translateZ(0) !important;
                      -webkit-backface-visibility: hidden !important;
                      -webkit-perspective: 1000 !important;
                  }
              `
            : css`
                  border-bottom: 1px solid #eee;
                  background-color: ${theme.colors.white};
              `}

    display: flex;
    justify-content: space-between;
    align-items: center;

    .profile-picture {
        cursor: pointer;
    }

    .profile-expansion {
        @media (max-width: 1250px) {
            display: block;
        }
        display: none;
    }

    .left {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 100%;
        p {
            white-space: nowrap;
        }
        @media (max-width: 750px) {
            gap: 10px;
        }
    }

    .right {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 20px;
        width: 100%;
        @media (max-width: 750px) {
            gap: 10px;
        }
    }

    .logo {
        display: flex;
        align-items: center;
        gap: 10px;

        @media (max-width: 1250px) {
            h5 {
                display: none;
            }
        }
    }
    .search {
        width: 100%;
        max-width: 500px;
        input {
            border: ${({ isLandingPage }) =>
                isLandingPage ? css`1px solid ${theme.colors.violet}` : css`1px solid ${theme.colors.placeholderText}99`};
            :hover,
            :focus,
            :active {
                border: ${({ isLandingPage }) =>
                    isLandingPage ? css`1px solid ${theme.colors.violet}` : css`1px solid ${theme.colors.contentBody}99`};
            }
        }

        @media (max-width: 1150px) {
            width: auto;
            max-width: auto;
        }
    }
    @media (max-width: 750px) {
        height: 60px;
        ${({ hideOnScrollDownMobile }) =>
            hideOnScrollDownMobile
                ? css`
                      transform: translateY(calc(-1 * var(--navbar-height)));
                  `
                : ''}
    }
    .mobile {
        @media (max-width: 1150px) {
            display: block;
        }
        display: none;
    }
    .tablet {
        @media (min-width: 1151px) {
            display: block;
        }
        display: none;
    }
    .mobile {
        @media (max-width: 1150px) {
            display: block;
        }
        display: none;
    }
    .desktop {
        @media (min-width: 1251px) {
            display: block;
        }
        display: none;
    }
    .pro {
        @media (min-width: 577px) {
            display: block;
        }
        display: none;
    }
`

interface Props {
    isLandingPage: boolean
    hasContentCampaignRunning: boolean
    isHomepage?: boolean
}

const Header = ({ isLandingPage, isHomepage, hasContentCampaignRunning }: Props) => {
    const [showUserDropdown, setShowUserDropdown] = useState(false)
    const [showTaxonomyMenuWindow, setShowTaxonomyMenuWindow] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0)
    const [snapScrollPosition, setSnapScrollPosition] = useState(0)
    const prevScrollPosition = usePrevious(scrollPosition)
    const menuRef = useRef<HTMLDivElement>(null)
    const mobileMenuRef = useRef<HTMLDivElement>(null)

    const router = useRouter()
    const { locale } = router
    const referrerScreen = getReferrerScreen(router)
    const parsedLocale = parseLocale(locale)
    const authenticatedUser = useSelector((state: AppState) => state.user)
    const countryLanguageConfig = getCountryLanguageConfig(parsedLocale.contentLanguageCode)
    const countryConfig = getCountryConfig(parsedLocale.countryCode)
    const hasKnowunityPro = countryConfig?.hasPlusPlan
    const isLaunched = countryLanguageConfig?.isLaunched

    useEffect(() => {
        if (!hasKnowunityPro) return

        trackEvent(AnalyticsEventProPlanMenuCTAImpression, { referrerScreen })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasKnowunityPro])

    const changeTaxonomyMenuWindowState = (show: boolean) => setShowTaxonomyMenuWindow(show)

    const userDropdownRef = useRef<HTMLLIElement>(null)

    const toggleUserDropdown = () => setShowUserDropdown(!showUserDropdown)

    const trackMobileOpenAppButtonClick = () => trackEvent(AnalyticsEventNavbarOpenAppButtonClick, {})

    const handleMouseDown = (event: MouseEvent) => {
        const isClickOutsideUserDropDown = !userDropdownRef.current?.contains(event.target as Node)

        if (isClickOutsideUserDropDown) {
            setShowUserDropdown(false)
        }
    }

    useEffect(() => {
        const snapScroll = document.querySelector('#snap-scroll')
        document.addEventListener('mousedown', handleMouseDown)
        snapScroll && snapScroll.addEventListener('scroll', listenToSnapScrollScroll)
        window.addEventListener('scroll', listenToScroll)
        return () => {
            window.removeEventListener('scroll', listenToScroll)
            snapScroll && snapScroll.removeEventListener('scroll', listenToSnapScrollScroll)
            document.removeEventListener('mousedown', handleMouseDown)
        }
    }, [])

    const listenToScroll = () => {
        setScrollPosition(document.body.scrollTop || document.documentElement.scrollTop)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const listenToSnapScrollScroll = (event: any) => {
        setSnapScrollPosition(event.target.scrollTop)
    }

    const hideOnMobile = useMemo(
        () => !!(prevScrollPosition && scrollPosition > prevScrollPosition && scrollPosition > 50),
        [prevScrollPosition, scrollPosition]
    )

    const isSnapScrolledDown = snapScrollPosition > 700

    if (isSnapScrolledDown) return null

    return (
        <Container isLandingPage={isLandingPage} hideOnScrollDownMobile={hideOnMobile}>
            <div className="left">
                <div className="mobile" ref={mobileMenuRef}>
                    {!hasContentCampaignRunning ? (
                        <MenuOption
                            isLandingPage={isLandingPage}
                            changeMenuState={setShowTaxonomyMenuWindow}
                            isMenuOptionOpen={showTaxonomyMenuWindow}
                        />
                    ) : null}
                </div>

                <Link href="/" passHref prefetch={false}>
                    <div className="logo">
                        <img src={isLandingPage ? IconDark.src : Icon.src} alt="Knowunity Logo" width={18} height={25.5} />

                        <div className={!hasContentCampaignRunning ? 'tablet' : ''}>
                            <Body1
                                fontSize={18}
                                fontFamily="var(--font-poppins)"
                                color={isLandingPage ? theme.colors.white : theme.colors.accentBlue}
                            >
                                Knowunity
                            </Body1>
                        </div>
                    </div>
                </Link>
                <div className="tablet">
                    <Row>
                        {!hasContentCampaignRunning ? (
                            <>
                                <div ref={menuRef}>
                                    <MenuOption
                                        isLandingPage={isLandingPage}
                                        changeMenuState={setShowTaxonomyMenuWindow}
                                        isMenuOptionOpen={showTaxonomyMenuWindow}
                                    />
                                </div>
                                <HeaderFloatingMenu
                                    isLandingPage={isLandingPage}
                                    showCompanyOptions
                                    hasContentCampaignRunning={hasContentCampaignRunning}
                                />
                            </>
                        ) : null}
                    </Row>
                </div>
                {!hasContentCampaignRunning && !isHomepage ? (
                    <div className="search">
                        <div className="mobile">
                            <MobileSearchBar isHomepage={isLandingPage} />
                        </div>
                        <div className="tablet">
                            <SearchBarRedirect referrerScreen={''} />
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="right">
                <ClientOnly>
                    <LocaleSwitcher parsedLocale={parsedLocale} isDarkTheme={isLandingPage} />
                </ClientOnly>
                <div className="pro">
                    <Row>
                        {hasKnowunityPro ? (
                            <Link href={proPlanRoute} passHref prefetch={false}>
                                <Body2
                                    fontWeight={600}
                                    fontFamily="var(--font-poppins)"
                                    color={isLandingPage ? theme.colors.white : theme.colors.black}
                                >
                                    Knowunity Pro
                                </Body2>
                            </Link>
                        ) : null}
                        <Link href={aiUsageRoute} passHref prefetch={false}>
                            <Body2
                                fontWeight={600}
                                fontFamily="var(--font-poppins)"
                                color={isLandingPage ? theme.colors.white : theme.colors.black}
                            >
                                AI Knowunity
                            </Body2>
                        </Link>
                    </Row>
                </div>
                <ClientOnly>
                    {isLaunched ? (
                        <div className="mobile">
                            <OpenTheAppDownloadButton
                                onClick={trackMobileOpenAppButtonClick}
                                backgroundColor={theme.colors.deepSeaBlue}
                                isDark
                                fitContent
                                height={'45px'}
                                referrerScreen="know-mobile-navbar-button"
                            />
                        </div>
                    ) : null}
                </ClientOnly>
                {!hasContentCampaignRunning ? (
                    <div className="desktop">
                        {authenticatedUser ? (
                            <div className="profile-picture" onClick={toggleUserDropdown}>
                                <ProfilePicture user={authenticatedUser} size={36} boxShadow />
                                <UserProfileDropDown
                                    open={showUserDropdown}
                                    dropdownRef={userDropdownRef}
                                    handleCloseDropdown={toggleUserDropdown}
                                    handleLinksWithinWebapp={!hasContentCampaignRunning}
                                    position={{
                                        xOffset: 200,
                                        yOffset: 0,
                                    }}
                                />
                            </div>
                        ) : (
                            <Link href={signInRoute({ referrerScreen: 'header' })} passHref prefetch={false}>
                                <Row>{isLandingPage ? <ProfileIcon /> : <ProfileDarkIcon />}</Row>
                            </Link>
                        )}
                    </div>
                ) : null}
                {!hasContentCampaignRunning ? (
                    <div className="tablet">
                        <HeaderDownloadDesktopButton
                            isLaunched={isLaunched}
                            isLandingPage={isLandingPage}
                            referrerScreen={referrerScreen}
                        />
                    </div>
                ) : null}
            </div>

            <FloatingTaxonomyMenu
                showMenu={showTaxonomyMenuWindow}
                changeMenuState={changeTaxonomyMenuWindowState}
                menuRef={menuRef}
                mobileMenuRef={mobileMenuRef}
                hasContentCampaignRunning={hasContentCampaignRunning}
            />
        </Container>
    )
}

export default Header
