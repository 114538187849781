import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { Know, KnowFormat } from 'interfaces/Know'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import KnowService from 'services/KnowService'
import { ApiError } from 'services/ApiService'
import { ErrorTypes } from 'lib/constants/ErrorTypes'
import { trackPromise } from 'react-promise-tracker'
import { SubjectBadge } from 'components/elements/Badge'
import LoadingArea from 'components/elements/LoadingArea'
import theme from 'stories/utils/theme'
import { Body1, Body2, Headline5 } from 'stories/elements/Typography/Text'
import ProfilePicture from 'components/elements/ProfilePicture'
import KnowLikes from 'assets/icons/know-likes-icon.svg'
import KnowViews from 'assets/icons/know-views-icon.svg'
import PDFIcon from 'assets/icons/pdf.svg'
import FlashcardIcon from 'assets/icons/flashcard_big.svg'
import { knowRoute } from 'lib/constants/routes'
import _useTranslation from 'lib/hooks/_useTranslation'
import { parseLocale } from 'lib/constants/localization'
import { getFirstLocalSubjectFromKnowSubjects } from 'lib/formatting/subjects'
import { useRouter } from 'next/router'
import { ClientOnly } from 'components/elements/ClientOnly'
import { isKnowNotIndexable } from 'lib/features/Know/knowSSR'
import KnowImpressionTracking from 'lib/tracking/KnowImpressionTracking'

interface ContainerProps {
    highlight?: boolean
    mobileHighlight?: boolean
    hoverColor?: string
    startSnapScroll?: boolean
    disabledLinking?: boolean
}

const Container = styled.div<ContainerProps>`
    width: 300px;
    height: auto;
    min-height: 300px;
    margin: 10px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius.card};
    cursor: ${({ disabledLinking }) => (disabledLinking ? 'normal' : 'pointer')};
    ${({ startSnapScroll }) =>
        startSnapScroll
            ? css`
                  scroll-snap-align: start;
              `
            : ''}

    .top {
        padding: 15px;
        display: grid;
        grid-template-columns: 1fr auto;
    }

    .badges {
        display: flex;
        flex-direction: row;
        flex-flow: wrap;
        row-gap: 3px;
        color: #c2c7cc;
        align-items: center;
        justify-content: flex-start;
    }
    .title {
        display: grid;
        grid-gap: 5px;
    }
    .profile {
        display: grid;
        place-content: center;
        grid-gap: 10px;
    }

    .bottom {
        padding: 15px;
        display: grid;
        grid-gap: 10px;
        .stats {
            display: flex;
            justify-content: space-between;
            .icon {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }
    }
    a {
        text-decoration: none !important;
    }
    ${({ highlight, hoverColor }) =>
        highlight
            ? css`
                  position: relative;
                  bottom: 11px;
                  right: 9px;
                  margin-left: 9px;
                  width: 300px;
                  border: 3px solid ${hoverColor};
                  border-radius: ${theme.borderRadius.normal};
              `
            : ''};
    ${({ mobileHighlight, hoverColor }) =>
        mobileHighlight
            ? css`
                  @media (max-width: 750px) {
                      position: relative;
                      bottom: 11px;
                      right: 9px;
                      margin-left: 9px;
                      width: 306px;
                      border: 3px solid ${hoverColor};
                      border-radius: ${theme.borderRadius.normal};
                  }
              `
            : ''};
`

const KnowImage = styled.img`
    object-fit: cover;
    object-position: top;
`

const EmptyPreview = styled.div`
    height: 145px;
    width: 325px;
    background: ${theme.colors.errorRed};

    display: grid;
    place-content: center;

    svg {
        fill: white;
        width: 50px;
        height: 50px;
    }
`

const Description = styled(Body1)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const Headline = styled(Headline5)`
    max-width: 225px;
    overflow: hidden;
    text-overflow: ellipsis;
`

const loadingAreas = {
    know: 'knowContent',
}

interface Props {
    knowUuid: string
    highlight?: boolean
    mobileHighlight?: boolean
    hoverColor?: string
    startSnapScroll?: boolean
    know?: Know
    referrerScreen?: string
    disabledLinking?: boolean
}

const ComplexKnowCard = (props: Props) => {
    const [know, setKnow] = useState<Know | null>(props.know ?? null)
    const { t: tCommon } = _useTranslation('common')
    const router = useRouter()
    const parsedLocale = parseLocale(router.locale)
    const referrerScreen = props.referrerScreen
    const localizedSubject = know ? getFirstLocalSubjectFromKnowSubjects(know?.subjects, parsedLocale.countryCode) : null
    const { disabledLinking } = props

    const fetchData = useCallback(() => {
        if (props.know) return

        trackPromise(
            KnowService.get(props.knowUuid)
                .then((know) => {
                    setKnow(know)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        console.error(error)
                    } else {
                        throw error
                    }
                }),
            loadingAreas.know
        )
    }, [props.know, props.knowUuid, tCommon])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if (!know) return null

    const redirectToKnow = () => !disabledLinking && router.push(knowRoute(know.slug, { referrerScreen }))

    const KnowWrapper = (props: { children: ReactNode }) =>
        isKnowNotIndexable(know) || disabledLinking ? (
            <div onClick={redirectToKnow}>{props.children}</div>
        ) : (
            <Link href={knowRoute(know.slug, { referrerScreen })} passHref prefetch={false} legacyBehavior>
                <a>{props.children}</a>
            </Link>
        )

    const showFlashcardsIcon = know.knowFormat === KnowFormat.Flashcards

    return (
        <>
            {know ? <KnowImpressionTracking know={know} referrerScreen={referrerScreen} /> : null}

            <KnowWrapper>
                <Container
                    highlight={props.highlight ?? false}
                    mobileHighlight={props.mobileHighlight ?? false}
                    hoverColor={props.hoverColor ?? theme.colors.knowunityBlue}
                    startSnapScroll={props.startSnapScroll}
                    disabledLinking={disabledLinking}
                >
                    <LoadingArea area={loadingAreas.know}>
                        <div className="top">
                            <div className="title">
                                <Headline color={theme.colors.headlineDark}>{know.seoTitle ?? know.title}</Headline>
                                <div className="badges">
                                    <ClientOnly>
                                        {localizedSubject ? (
                                            <SubjectBadge key={localizedSubject.id} subject={localizedSubject} />
                                        ) : null}
                                    </ClientOnly>
                                </div>
                            </div>
                            <div className="profile">
                                <ProfilePicture user={know.knower.user} size={30} />
                            </div>
                        </div>
                        <div className="image">
                            {know.thumbnailSmallUrl ? (
                                <KnowImage
                                    src={know.thumbnailSmallUrl}
                                    alt={`Know ${know.seoTitle ?? know.title} thumbnail`}
                                    width={300}
                                    height={145}
                                    loading="lazy"
                                />
                            ) : (
                                <EmptyPreview>{showFlashcardsIcon ? <FlashcardIcon /> : <PDFIcon />}</EmptyPreview>
                            )}
                        </div>
                        <div className="bottom">
                            <Description>{know.seoDescription ?? know.description}</Description>
                            <div className="stats">
                                <div className="icon">
                                    <KnowViews />
                                    <Body2>{know.views}</Body2>
                                </div>
                                <div className="icon">
                                    <KnowLikes />
                                    <Body2>{know.likes}</Body2>
                                </div>
                            </div>
                        </div>
                    </LoadingArea>
                </Container>
            </KnowWrapper>
        </>
    )
}

export default ComplexKnowCard
